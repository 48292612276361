@font-face {
    font-family: 'Inter';
    src: url('fonts/Inter_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('fonts/Inter_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

/*

xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px

*/

.headerTitle {
    font: normal normal 600 30px/65px Inter !important;
    letter-spacing: 1px;
    color: #000000;
    opacity: 1;
}

.headerTitleMobile {
  font: normal normal 600 30px/35px Inter !important;
  letter-spacing: 1px;
  color: #000000;
  opacity: 1;
}

.headerSubtitle {
  font: normal normal 600 26px/33px Inter !important;
  letter-spacing: 0px;
  color: #808080;
  opacity: 1;
}

.highlightedProminentTitle {
  font: normal normal 600 54px/65px Inter !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 600px) {
  .highlightedProminentTitle  {
    font-size: 42px !important;
    line-height: 55px !important;
  }
}

.highlightedTitle {
  font: normal normal 600 38px/60px Inter !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 600px) {
  .highlightedTitle  {
    font-size: 32px !important;
    line-height: 45px !important;
  }
}

.highlightedSecondaryTitle {
  font: normal normal 600 48px/58px Inter !important;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

@media (max-width: 600px) {
  .highlightedSecondaryTitle {
    font-size: 36px !important;
    line-height: 52px !important;
  }
}

.primarySubtitle {
  font: normal normal 600 24px/29px Inter !important;
  letter-spacing: 1px;
  color: #5C59E9;
  opacity: 1;
}

.secondaryTitle {
  font: normal normal 600 40px/48px Inter !important;
  letter-spacing: 1px;
  color: #5C59E9;
  opacity: 1;
}

@media (max-width: 600px) {
  .secondaryTitle {
    font-size: 42px !important;
    line-height: 55px !important;
  }
}

.descriptionText {
  font: normal normal normal 24px/33px Inter !important;
  letter-spacing: 0px;
  color: #898989;
  opacity: 1;
}

.actionButtonPrimary {
    font: normal normal 600 20px/20px Inter !important;
    letter-spacing: 0px;
    color: #F6F6F9;
    border-radius: 10px !important;
    box-shadow: none !important;
    opacity: 1;
    padding-left: 35px !important;
    padding-right: 35px !important;
    height: 70px !important;
    text-transform: none !important;
    background-color: #F9B12D !important;
}

.secondaryButtonPrimary {
  font: normal normal 600 20px/20px Inter !important;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 10px !important;
  box-shadow: none !important;
  opacity: 1;
  height: 70px !important;
  text-transform: none !important;
  background-color: #6950F1 !important;
}

.footerPrimary {
  font: normal normal bold 15px/60px Inter !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.footerSecondary {
  font: normal normal bold 15px/60px Inter !important;
  letter-spacing: 0px;
  color: #636363;
  opacity: 1;
}

